import React from 'react';
import './ManagerDashboard.css';

const ManagerDashboard = () => {
  return (
    <div className="manager-dashboard">
      <h2>Manager Dashboard</h2>
      {/* İstatistik kartları, grafikler ve diğer bileşenler buraya eklenecek */}
    </div>
  );
};

export default ManagerDashboard;
