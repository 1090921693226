import React, { useEffect, useState } from 'react';
import { auth, db } from '../../services/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import './NotificationSettings.css';

const NotificationSettings = () => {
  const [settings, setSettings] = useState({
    emailNotifications: true,
    pushNotifications: true,
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        setSettings(userData.notificationSettings || settings);
      }
    };
    fetchSettings();
  }, [settings]); // 'settings' bağımlılığını ekleyin

  const handleToggle = async (type) => {
    const newSettings = {
      ...settings,
      [type]: !settings[type]
    };
    setSettings(newSettings);

    const user = auth.currentUser;
    if (user) {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, { notificationSettings: newSettings });
    }
  };

  return (
    <div className="notification-settings">
      <h2>Notification Settings</h2>
      <div className="setting">
        <label>Email Notifications</label>
        <input
          type="checkbox"
          checked={settings.emailNotifications}
          onChange={() => handleToggle('emailNotifications')}
        />
      </div>
      <div className="setting">
        <label>Push Notifications</label>
        <input
          type="checkbox"
          checked={settings.pushNotifications}
          onChange={() => handleToggle('pushNotifications')}
        />
      </div>
    </div>
  );
};

export default NotificationSettings;
