import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase';
import { collection, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';
import './Tasks.css';

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');

  useEffect(() => {
    const fetchTasks = async () => {
      const user = auth.currentUser;
      const querySnapshot = await getDocs(collection(db, 'tasks'));
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTasks(data.filter(task => task.userId === user.uid));
    };
    fetchTasks();
  }, []);

  const handleAddTask = async (e) => {
    e.preventDefault();
    if (newTask.trim() === '') return;

    const user = auth.currentUser;
    await addDoc(collection(db, 'tasks'), {
      userId: user.uid,
      text: newTask,
      status: 'pending',
      timestamp: new Date()
    });

    setNewTask('');
    const querySnapshot = await getDocs(collection(db, 'tasks'));
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTasks(data.filter(task => task.userId === user.uid));
  };

  const handleUpdateStatus = async (id, status) => {
    const taskDoc = doc(db, 'tasks', id);
    await updateDoc(taskDoc, { status });
    const updatedTasks = tasks.map((task) => (task.id === id ? { ...task, status } : task));
    setTasks(updatedTasks);
  };

  return (
    <div className="tasks">
      <h2>Your Tasks</h2>
      <form onSubmit={handleAddTask}>
        <textarea
          placeholder="Add a new task"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          required
        />
        <button type="submit">Add Task</button>
      </form>
      <div className="task-list">
        {tasks.map((task, index) => (
          <div key={index} className="task-item">
            <p>{task.text}</p>
            <p>Status: {task.status}</p>
            <button onClick={() => handleUpdateStatus(task.id, 'completed')}>Complete</button>
            <button onClick={() => handleUpdateStatus(task.id, 'pending')}>Mark as Pending</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tasks;
