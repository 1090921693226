import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import './Holidays.css';

const Holidays = () => {
  const [holidays, setHolidays] = useState([]);
  const [newHoliday, setNewHoliday] = useState('');

  useEffect(() => {
    const fetchHolidays = async () => {
      const querySnapshot = await getDocs(collection(db, 'holidays'));
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setHolidays(data);
    };
    fetchHolidays();
  }, []);

  const handleAddHoliday = async (e) => {
    e.preventDefault();
    if (newHoliday.trim() === '') return;

    await addDoc(collection(db, 'holidays'), {
      date: newHoliday
    });

    setNewHoliday('');
    const querySnapshot = await getDocs(collection(db, 'holidays'));
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setHolidays(data);
  };

  const handleDeleteHoliday = async (id) => {
    await deleteDoc(doc(db, 'holidays', id));
    setHolidays(holidays.filter((holiday) => holiday.id !== id));
  };

  return (
    <div className="holidays">
      <h2>Manage Holidays</h2>
      <form onSubmit={handleAddHoliday}>
        <input
          type="date"
          value={newHoliday}
          onChange={(e) => setNewHoliday(e.target.value)}
          required
        />
        <button type="submit">Add Holiday</button>
      </form>
      <div className="holiday-list">
        {holidays.map((holiday, index) => (
          <div key={index} className="holiday-item">
            <p>{holiday.date}</p>
            <button onClick={() => handleDeleteHoliday(holiday.id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Holidays;
