import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './LeaveReports.css';

const LeaveReports = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchReports = async () => {
      const querySnapshot = await getDocs(collection(db, 'leaveRequests'));
      const data = querySnapshot.docs.map((doc) => doc.data());
      setReports(data);
    };
    fetchReports();
  }, []);

  return (
    <div className="leave-reports">
      <h2>Leave Reports</h2>
      <table>
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Reason</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report, index) => (
            <tr key={index}>
              <td>{report.startDate}</td>
              <td>{report.endDate}</td>
              <td>{report.reason}</td>
              <td>{report.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveReports;
