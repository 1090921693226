import React, { useState } from 'react';
import { db, auth } from '../../services/firebase';
import { collection, addDoc } from 'firebase/firestore';
import './RequestLeave.css';

const RequestLeave = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      setMessage('You need to be logged in to request leave.');
      return;
    }

    const leaveData = {
      userId: user.uid,
      startDate,
      endDate,
      reason,
      status: 'pending',
      timestamp: new Date(),
    };

    try {
      await addDoc(collection(db, 'leaveRequests'), leaveData);
      setMessage('Leave request submitted successfully.');
      setStartDate('');
      setEndDate('');
      setReason('');
    } catch (error) {
      setMessage('Error submitting leave request: ' + error.message);
    }
  };

  return (
    <div className="request-leave">
      <h2>Request Leave</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </label>
        <label>
          Reason:
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default RequestLeave;
