import { auth, db } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';

// Kullanıcı kaydı
export const registerUser = async (email, password, firstName, lastName) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;
  await setDoc(doc(db, 'users', user.uid), {
    firstName,
    lastName,
    email,
    role: 'user',
    notificationSettings: {
      emailNotifications: true,
      pushNotifications: true,
    }
  });
  return user;
};

// Kullanıcı girişi
export const loginUser = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  return userCredential.user;
};

// Kullanıcı çıkışı
export const logoutUser = async () => {
  await signOut(auth);
};

// Kullanıcı bilgilerini güncelleme
export const updateUserProfile = async (uid, profileData) => {
  const userDoc = doc(db, 'users', uid);
  await updateDoc(userDoc, profileData);
};

// Kullanıcı bilgilerini getirme
export const getUserProfile = async (uid) => {
  const userDoc = await getDoc(doc(db, 'users', uid));
  return userDoc.data();
};
