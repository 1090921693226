import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCK3PGM3BDzxNqSYkNeR0-DU77hDRyRFEw",
  authDomain: "aeg-medya-personel-takip.firebaseapp.com",
  projectId: "aeg-medya-personel-takip",
  storageBucket: "aeg-medya-personel-takip.appspot.com",
  messagingSenderId: "947934842831",
  appId: "1:947934842831:web:96ced1cd8363afe1384cba",
  measurementId: "G-P0C8GP0TFM"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const db = getFirestore(app);
const auth = getAuth(app);
const messaging = getMessaging(app);

export { db, auth, messaging };
