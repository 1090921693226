import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom'; // Link import edin
import { auth, db, messaging } from '../../services/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getToken } from 'firebase/messaging';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await getToken(messaging, { vapidKey: 'BLwLzwpQSuvRkiEK1TITfDnRAslaga5glQ4ODBzt4qNjHr-pNT-DyfoD_Byg-Takud9CUdbF09B77bJIMCGV5Iw' }); // Public key'inizi buraya koyun
      await updateDoc(doc(db, 'users', user.uid), { notificationToken: token });
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      
      // Kullanıcı rolüne göre yönlendirme yapma
      if (userData.role === 'admin') {
        history.push('/admin-dashboard');
      } else if (userData.role === 'manager') {
        history.push('/manager-dashboard');
      } else {
        history.push('/user-dashboard');
      }
      
      console.log('Kullanıcı giriş yaptı: ', user);
    } catch (error) {
      console.error('Giriş yapılırken hata oluştu: ', error);
    }
  };

  return (
    <div className="login">
      <form onSubmit={handleLogin}>
        <h2>Giriş Yap</h2>
        <input type="email" placeholder="E-posta" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <button type="submit">Giriş Yap</button>
      </form>
      <Link to="/reset-password" className="reset-link">Şifrenizi mi unuttunuz?</Link> {/* Şifre sıfırlama linki ekleyin */}
    </div>
  );
};

export default Login;
