import React from 'react';
import './Backup.css';

const Backup = () => {
  const handleBackup = () => {
    // Veritabanı yedekleme işlemi
    alert('Veritabanı yedekleme işlemi başlatıldı.');
  };

  return (
    <div className="backup">
      <h2>Veritabanı Yedekleme</h2>
      <button onClick={handleBackup}>Yedekle</button>
    </div>
  );
};

export default Backup;
