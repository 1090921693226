import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import ManagerDashboard from './pages/ManagerDashboard/ManagerDashboard';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import RequestLeave from './pages/RequestLeave/RequestLeave';
import ManageLeaveRequests from './pages/ManageLeaveRequests/ManageLeaveRequests';
import LeaveReports from './pages/LeaveReports/LeaveReports';
import Profile from './pages/Profile/Profile';
import LeaveHistory from './pages/LeaveHistory/LeaveHistory';
import Announcements from './pages/Announcements/Announcements';
import Tasks from './pages/Tasks/Tasks';
import Analytics from './pages/Analytics/Analytics';
import Roles from './pages/Roles/Roles';
import NotificationSettings from './pages/NotificationSettings/NotificationSettings';
import Holidays from './pages/Holidays/Holidays';
import Backup from './pages/Backup/Backup';
import ResetPassword from './pages/ResetPassword/ResetPassword'; // ResetPassword bileşenini import edin
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import { UserProvider } from './context/UserContext';
import useAuth from './hooks/useAuth';
import './App.css';

function App() {
  const user = useAuth();

  return (
    <Router>
      <UserProvider>
        <div className="App">
          <NavBar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password" component={ResetPassword} /> {/* ResetPassword bileşeni için route ekleyin */}
            {user && (
              <>
                <Route path="/admin-dashboard" component={AdminDashboard} />
                <Route path="/manager-dashboard" component={ManagerDashboard} />
                <Route path="/user-dashboard" component={UserDashboard} />
                <Route path="/request-leave" component={RequestLeave} />
                <Route path="/manage-leave-requests" component={ManageLeaveRequests} />
                <Route path="/leave-reports" component={LeaveReports} />
                <Route path="/profile" component={Profile} />
                <Route path="/leave-history" component={LeaveHistory} />
                <Route path="/announcements" component={Announcements} />
                <Route path="/tasks" component={Tasks} />
                <Route path="/analytics" component={Analytics} />
                <Route path="/roles" component={Roles} />
                <Route path="/notification-settings" component={NotificationSettings} />
                <Route path="/holidays" component={Holidays} />
                <Route path="/backup" component={Backup} />
              </>
            )}
          </Switch>
          <Footer />
        </div>
      </UserProvider>
    </Router>
  );
}

export default App;
