import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, serverTimestamp } from 'firebase/firestore';
import './Announcements.css';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState('');

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const querySnapshot = await getDocs(collection(db, 'announcements'));
      const data = querySnapshot.docs.map((doc) => doc.data());
      setAnnouncements(data);
    };
    fetchAnnouncements();
  }, []);

  const handleAddAnnouncement = async (e) => {
    e.preventDefault();
    if (newAnnouncement.trim() === '') return;

    await addDoc(collection(db, 'announcements'), {
      text: newAnnouncement,
      timestamp: serverTimestamp()
    });

    setNewAnnouncement('');
    const querySnapshot = await getDocs(collection(db, 'announcements'));
    const data = querySnapshot.docs.map((doc) => doc.data());
    setAnnouncements(data);
  };

  return (
    <div className="announcements">
      <h2>Announcements</h2>
      <form onSubmit={handleAddAnnouncement}>
        <textarea
          placeholder="Add a new announcement"
          value={newAnnouncement}
          onChange={(e) => setNewAnnouncement(e.target.value)}
          required
        />
        <button type="submit">Add Announcement</button>
      </form>
      <div className="announcement-list">
        {announcements.map((announcement, index) => (
          <div key={index} className="announcement-item">
            {announcement.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Announcements;
