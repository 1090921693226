import React, { useState, useEffect } from 'react';
import { db } from '../../services/firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import './ManageLeaveRequests.css';

// 'notificationToken' ve 'setNotificationToken' değişkenlerini kaldırın
const ManageLeaveRequests = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      const querySnapshot = await getDocs(collection(db, 'leaveRequests'));
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setLeaveRequests(data);
    };
    fetchLeaveRequests();
  }, []);

  const handleUpdateStatus = async (id, status) => {
    const leaveDoc = doc(db, 'leaveRequests', id);
    await updateDoc(leaveDoc, { status });
    setLeaveRequests((prevRequests) =>
      prevRequests.map((request) => (request.id === id ? { ...request, status } : request))
    );
  };

  return (
    <div className="manage-leave-requests">
      <h2>Manage Leave Requests</h2>
      <ul>
        {leaveRequests.map((request) => (
          <li key={request.id}>
            <p>{request.reason}</p>
            <p>{request.startDate} to {request.endDate}</p>
            <p>Status: {request.status}</p>
            <button onClick={() => handleUpdateStatus(request.id, 'approved')}>Approve</button>
            <button onClick={() => handleUpdateStatus(request.id, 'rejected')}>Reject</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageLeaveRequests;
