import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import './ResetPassword.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Şifre sıfırlama e-postası gönderildi!');
    } catch (error) {
      setMessage('Hata: ' + error.message);
    }
  };

  return (
    <div className="reset-password">
      <h2>Şifre Sıfırlama</h2>
      <form onSubmit={handleResetPassword}>
        <label>
          E-posta:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <button type="submit">Gönder</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPassword;
