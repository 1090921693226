import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCK3PGM3BDzxNqSYkNeR0-DU77hDRyRFEw",
  authDomain: "aeg-medya-personel-takip.firebaseapp.com",
  projectId: "aeg-medya-personel-takip",
  storageBucket: "aeg-medya-personel-takip.appspot.com",
  messagingSenderId: "947934842831",
  appId: "1:947934842831:web:96ced1cd8363afe1384cba",
  measurementId: "G-P0C8GP0TFM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const messaging = getMessaging(app);

getToken(messaging, { vapidKey: 'YOUR_PUBLIC_VAPID_KEY' }).then((currentToken) => {
  if (currentToken) {
    console.log('FCM Token:', currentToken);
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // Customize notification here
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon
  };

  new Notification(notificationTitle, notificationOptions);
});

export { auth, db, messaging };
