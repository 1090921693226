import React, { useEffect, useState } from 'react';
import { db } from '../../services/firebase';
import { collection, getDocs } from 'firebase/firestore';
import './Analytics.css';

const Analytics = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalLeaveRequests, setTotalLeaveRequests] = useState(0);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [approvedRequests, setApprovedRequests] = useState(0);
  const [rejectedRequests, setRejectedRequests] = useState(0);

  useEffect(() => {
    const fetchAnalytics = async () => {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const leaveRequestsSnapshot = await getDocs(collection(db, 'leaveRequests'));

      setTotalUsers(usersSnapshot.size);
      setTotalLeaveRequests(leaveRequestsSnapshot.size);
      setPendingRequests(leaveRequestsSnapshot.docs.filter(doc => doc.data().status === 'pending').length);
      setApprovedRequests(leaveRequestsSnapshot.docs.filter(doc => doc.data().status === 'approved').length);
      setRejectedRequests(leaveRequestsSnapshot.docs.filter(doc => doc.data().status === 'rejected').length);
    };

    fetchAnalytics();
  }, []);

  return (
    <div className="analytics">
      <h2>Analytics Reports</h2>
      <div className="analytics-cards">
        <div className="analytics-card">
          <h3>Total Users</h3>
          <p>{totalUsers}</p>
        </div>
        <div className="analytics-card">
          <h3>Total Leave Requests</h3>
          <p>{totalLeaveRequests}</p>
        </div>
        <div className="analytics-card">
          <h3>Pending Requests</h3>
          <p>{pendingRequests}</p>
        </div>
        <div className="analytics-card">
          <h3>Approved Requests</h3>
          <p>{approvedRequests}</p>
        </div>
        <div className="analytics-card">
          <h3>Rejected Requests</h3>
          <p>{rejectedRequests}</p>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
